import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Electronic gases`}</h4>
    <p>{`Gases are widely used in semiconductor fabrication, including common gases like nitrogen, hydrogen, helium, argon helium, and carbon dioxide as well as speciality electronic gases like nitrogen trifluoride, tungsten hexafluoride, hydrogen chloride, ammonia, disilane, germane, high-purity carbon dioxide, and nitrous oxide. In particular, the photolithography process uses various noble gases, such as neon, argon, and krypton, to create lasers to draw circuit patterns on wafers. (However, the most advanced photolithography equipment, EUV, uses a tin light source rather than a gas-based light source.)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      